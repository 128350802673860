import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email, { FNAME: name })
      .then((data) => {
        setMessage(data.msg);
      })
      .catch(() => {
        setMessage('sorry something has gobe wrong, please try again');
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handleNameChange = (event) => {
    setName(event.currentTarget.value);
  };

  return (
    <form onSubmit={(e) => { handleSubmit(e); }}>
      <div id="mc_embed_signup_scroll">
        <div className="mc-field-group">
          <label htmlFor="mce-FNAME">First Name </label>
          <input
            name="name"
            type="text"
            placeholder="Enter your name"
            onChange={(e) => { handleNameChange(e); }}
          />
        </div>
        <div className="mc-field-group">
          <label htmlFor="mce-EMAIL">Email Address </label>
          <input
            placeholder="Email address"
            name="email"
            type="text"
            onChange={(e) => { handleEmailChange(e); }}
          />
        </div>
        <div className="clear">
          <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" />
        </div>
        <div
          className="errors"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </form>
  );
};

export default EmailListForm;
