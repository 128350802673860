import React from 'react';
import MCForm from './form';
import '../assets/styles/components/signup.scss';

const SignUp = () => (
  <div className="signup-wrapper">
    <div className="signup-form-wrapper">
      <div className="singup-form-text-wrapper">
        <h2>Sign up. Hear.</h2>
        <p>
          Enter your details and each day of September, we&apos;ll release a new piece and email you
          the link. Admission is free but we&apos;d welcome a donation to this&nbsp;
          <a href="https://earfest2020.raisely.com/" rel="noreferrer" target="_blank">incredible charity</a>
          .
        </p>
      </div>
      <div id="mc_embed_signup">
        <MCForm />
      </div>
    </div>
    <a href="https://www.beep.fm/earfest/record" rel="noreferrer" target="_blank" className="call-to-action-wrapper">
      <div className="call-to-action">
        <span>
          Click to be part of the art
        </span>
      </div>
    </a>
  </div>
);

export default SignUp;
