import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import YouTube from 'react-youtube';
import DaysLeft from './daysLeft';
import '../assets/styles/components/todays-entry.scss';

const TodaysEntry = () => {
  const artist = useStaticQuery(graphql`
    query {
      allArtist {
        edges {
          node {
            name,
            slug,
            releaseDate,
            video,
            body
          }
        }
      }
    }
  `);

  const featuredPost = (artists) => {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    const bodyCopy = (text) => text.replace(/(<([^>]+)>)/gi, '');

    return artists.map(({ node }) => {
      const days = DaysLeft(node.releaseDate);

      if (Math.ceil(days) === 0) {
        return (
          <div className="todays-entry-wrapper" key={node.slug}>
            <h2 className="featured-title">Today&apos;s ear piece</h2>
            <div className="featured-artist">
              <div className="video-wrapper">
                <YouTube containerClassName="inner-video-wrapper" videoId={node.video} opts={opts} />
              </div>
              <div className="featured-artist-copy-wrapper">
                <h3 className="artist-name">{node.name}</h3>
                <div className="description">
                  {bodyCopy(node.body)}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <>
      {featuredPost(artist.allArtist.edges)}
    </>
  );
};

export default TodaysEntry;
