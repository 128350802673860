import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Artists from '../components/artists';
import TodaysEntry from '../components/todaysEntry';
import SignUp from '../components/signup';
import '../assets/styles/pages/home.scss';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerTabletImage: file(relativePath: { eq: "banner-ipad.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bannerMobileImage: file(relativePath: { eq: "banner-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Home" />
      <div className="banner-image-wrapper">
        <Link to="/">
          <Img fluid={data.bannerImage.childImageSharp.fluid} className="show-desktop" />
          <Img fluid={data.bannerTabletImage.childImageSharp.fluid} className="show-tablet" />
          <Img fluid={data.bannerMobileImage.childImageSharp.fluid} className="show-mobile" />
        </Link>
      </div>
      <TodaysEntry />
      <h1 className="intro-wrapper">
        <span>Thirty artists. Thirty days.</span>
        Lend an ear as we celebrate the wonders of audio in all its forms.
        <br />
        Register for EarFest now and each day of September you’ll receive an
        original piece from our selected artists, incorporating this year’s theme ‘
        <em>pearl</em>
        ’.
        <br />
        Whether it’s a song, story or sketch, slam poem or binaural beat,
        we guarantee you the best seat in the theatre of your mind.
      </h1>
      <SignUp />
      <Artists />
    </Layout>
  );
};

export default IndexPage;
